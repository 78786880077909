module.exports = {
  siteTitle: "KidrocaBlog - a personal website", // <title>
  shortSiteTitle: "KidrocaBlog - personal website", // <title> ending for posts and pages
  siteDescription: "KidrocaBlog is a personal website.",
  siteUrl: "https://www.kidroca.com",
  // pathPrefix: "",
  siteImage: "preview.jpg",
  siteLanguage: "en",

  /* author */
  authorName: "peter velkov",
  authorTwitterAccount: "kidroca1",

  /* info */
  headerTitle: "peter velkov",
  headerSubTitle: "uses webstorm to create a website",

  /* manifest.json */
  manifestName: "Kidroca - a personal blog",
  manifestShortName: "kidrocaBlog", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#666",
  manifestDisplay: "standalone",

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
  // Replace your email adress with md5-code.
  // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
  // gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
  gravatarImgMd5: "https://s.gravatar.com/avatar/1ca46b251914c21fc6be01296a8b8a6c?s=80",

  // social
  authorSocialLinks: [
    { name: "github", url: "https://github.com/kidroca" },
    { name: "twitter", url: "https://twitter.com/kidroca1" },
    { name: "facebook", url: "http://facebook.com/kidroca" }
  ]
};
